import { StytchHeadlessClient } from '@stytch/vanilla-js/headless'

export default defineNuxtPlugin(nuxtApp => {
  const runtimeConfig = useRuntimeConfig()
  const stytch: StytchHeadlessClient = new StytchHeadlessClient(
    runtimeConfig.public.STYTCH_PUBLIC_TOKEN
  )

  return { provide: { stytch } }
})
