export const useBreakpointsStore = defineStore('breakpoints', () => {
  const appHeight = ref(0)
  const appWidth = ref(0)

  const breakpoints = useBreakpoints({
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1440,
  })

  const current = computed(() => {
    return breakpoints.current().value.at(-1) ?? 'sm'
  })

  const isMobile = computed(() => {
    return breakpoints.smaller('sm').value
  })

  return { appHeight, appWidth, current, isMobile }
})
