<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'

export interface Props {
  context: FormKitFrameworkContext
}

const { context } = defineProps<Props>()
const { value, choices } = context

const selections = ref<string[]>(value)

const handleChoice = (choice: string) => {
  const choiceIndex = selections.value.indexOf(choice)
  choiceIndex > -1 ? selections.value.splice(choiceIndex, 1) : selections.value.push(choice)

  context.node.input(selections.value)
}
</script>

<template>
  <div class="flex flex-wrap items-center gap-2">
    <div
      v-for="(choice, index) in choices"
      class="cursor-pointer rounded-2xl border border-black-20 px-6 py-4 font-semibold text-black-100 hover:border-black-50 hover:bg-black-10 focus-visible:bg-black-10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black-30 active:bg-black-10"
      :class="{ 'border-black-90 bg-black-10': selections.includes(choice) }"
      :key="index"
      :active="selections.includes(choice)"
      @click="handleChoice(choice)"
    >
      {{ choice }}
    </div>
  </div>
</template>
