import revive_payload_client_9qu9mq4eSN from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.31_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup_3zbqycwrjwbf35yunxwgowzc3i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_nXAwvakHEG from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.31_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup_3zbqycwrjwbf35yunxwgowzc3i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_XUj99MJ0cU from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.31_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup_3zbqycwrjwbf35yunxwgowzc3i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_RkQyadnnPu from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.31_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup_3zbqycwrjwbf35yunxwgowzc3i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_cOi29DY8HA from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.16.4_typescript@5.4.5_vue@3.4.24/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/.nuxt/components.plugin.mjs";
import prefetch_client_J8KyYZeFgz from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.31_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup_3zbqycwrjwbf35yunxwgowzc3i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_vS54IvIste from "/opt/render/project/src/node_modules/.pnpm/@sentry+nuxt@8.28.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_ecky5trcq33xntaqtkww5644re/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/render/project/src/.nuxt/sentry-client-config.mjs";
import i18n_SCYKwxJLjs from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.16.4_vue@3.4.24/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_k1ORsThjlm from "/opt/render/project/src/node_modules/.pnpm/@hexdigital+nuxt-segment@1.3.5_rollup@4.16.4/node_modules/@hexdigital/nuxt-segment/dist/runtime/plugin.client.mjs";
import motion_z7hMaASDQl from "/opt/render/project/src/node_modules/.pnpm/@vueuse+motion@2.1.0_rollup@4.16.4_vue@3.4.24/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import formkitPlugin_pZqjah0RUG from "/opt/render/project/src/.nuxt/formkitPlugin.mjs";
import chunk_reload_client_FIwaEHNJCe from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.31_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup_3zbqycwrjwbf35yunxwgowzc3i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auto_animate_uTpJuTGsMC from "/opt/render/project/src/plugins/auto-animate.ts";
import dollars_client_LRBK5r0fQn from "/opt/render/project/src/plugins/dollars.client.ts";
import event_bus_S6Iq1iGe5d from "/opt/render/project/src/plugins/event-bus.ts";
import micromark_client_KcbvnxJfxV from "/opt/render/project/src/plugins/micromark.client.ts";
import ofetch_TmnSnS1Swe from "/opt/render/project/src/plugins/ofetch.ts";
import stytch_client_mnNUxqaOyf from "/opt/render/project/src/plugins/stytch.client.ts";
export default [
  revive_payload_client_9qu9mq4eSN,
  unhead_nXAwvakHEG,
  router_XUj99MJ0cU,
  payload_client_RkQyadnnPu,
  plugin_vue3_cOi29DY8HA,
  components_plugin_KR1HBZs4kY,
  prefetch_client_J8KyYZeFgz,
  sentry_client_vS54IvIste,
  sentry_client_config_o34nk2sJbg,
  i18n_SCYKwxJLjs,
  plugin_client_k1ORsThjlm,
  motion_z7hMaASDQl,
  formkitPlugin_pZqjah0RUG,
  chunk_reload_client_FIwaEHNJCe,
  auto_animate_uTpJuTGsMC,
  dollars_client_LRBK5r0fQn,
  event_bus_S6Iq1iGe5d,
  micromark_client_KcbvnxJfxV,
  ofetch_TmnSnS1Swe,
  stytch_client_mnNUxqaOyf
]