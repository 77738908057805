import { micromark } from 'micromark'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('markdown', {
    mounted(el, binding) {
      if (binding.value) {
        const content = micromark(binding.value)
        el.innerHTML = content
      }
    },
    // getSSRProps(binding, vnode) {
    //   // you can provide SSR-specific props here
    //   console.log('micromark.ts :13', binding, vnode)
    //   return {}
    // },
  })
})
