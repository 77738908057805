export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('dollars', {
    mounted: (el, binging) => {
      el.innerText = (binging.value / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },
    updated: (el, binding) => {
      if (binding.value !== binding.oldValue) {
        el.innerText = (binding.value / 100).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }
    },
    // getSSRProps(binding, vnode) {
    //   // you can provide SSR-specific props here
    //   console.log('micromark.ts :13', binding, vnode)
    //   return {}
    // },
  })
})
