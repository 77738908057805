import { defineFormKitConfig, createInput } from '@formkit/vue'
import { createProPlugin, inputs } from '@formkit/pro'
import { createAutoAnimatePlugin, createAutoHeightTextareaPlugin } from '@formkit/addons'
import { generateClasses } from '@formkit/themes'
import { genesisIcons } from '@formkit/icons'
import theme from './lib/formkit/formkit-theme'
import '@formkit/pro/genesis'
import CurrencyInput from '@/lib/formkit/inputs/CurrencyInput.vue'
import ChoiceOption from '@/lib/formkit/inputs/ChoiceOption.vue'
import MultiChoice from '@/lib/formkit/inputs/MultiChoice.vue'

const pro = createProPlugin('fk-3ddd943869', inputs)

export default defineFormKitConfig({
  plugins: [pro, createAutoAnimatePlugin(), createAutoHeightTextareaPlugin()],
  config: {
    classes: generateClasses(theme),
  },
  icons: {
    ...genesisIcons,
  },
  inputs: {
    currency: createInput(CurrencyInput, {
      props: ['digits'],
    }),
    buttonChoice: createInput(ChoiceOption, {
      props: ['choices', 'isBoolean', 'value'],
    }),
    multiChoice: createInput(MultiChoice, {
      props: ['choices', 'value'],
    }),
  },
})
