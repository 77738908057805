import { ofetch } from 'ofetch'

export default defineNuxtPlugin(nuxtApp => {
  const runtimeConfig = useRuntimeConfig()

  globalThis.$fetch = ofetch.create({
    baseURL: runtimeConfig.API_URL,
    credentials: 'include',

    onResponse(context) {},
    onRequest(context) {},
    onRequestError({ error }) {
      console.log('ofetch.ts :11', error)
    },
  })
})
