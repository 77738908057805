<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'

export interface Props {
  context: FormKitFrameworkContext
}

const { context } = defineProps<Props>()

const { value, isBoolean, choices: orgChoice } = context
const choices = orgChoice as string[]

const valueCasted = value !== undefined && isBoolean ? booleanToString(value) : value
const selectedIndex = ref<number>(choices.findIndex(it => it === valueCasted))

const handleChoice = (choice: string, index: number) => {
  selectedIndex.value = index
  isBoolean ? context.node.input(booleanify(choice)) : context.node.input(choice)
}

function booleanToString(bool: boolean): string {
  return bool ? 'Yes' : 'No'
}

function booleanify(value: string): boolean {
  const truthy: string[] = ['true', 'True', 'Yes', '1']

  return truthy.includes(value)
}
</script>

<template>
  <div class="flex flex-wrap items-center gap-2">
    <div
      v-for="(choice, index) in choices"
      class="cursor-pointer rounded-2xl border border-black-20 px-6 py-4 text-center font-semibold text-black-100 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-black-30 active:border-black-80 active:bg-black-10 max-sm:flex-grow"
      :class="{
        'border-black-80 bg-black-10': selectedIndex === index,
        'hover:border-black-30 hover:bg-black-5': selectedIndex !== index,
      }"
      :key="index"
      :tabindex="0"
      :active="selectedIndex === index"
      @click="handleChoice(choice, index)"
    >
      {{ choice }}
    </div>
  </div>
</template>
