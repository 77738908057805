import mitt from 'mitt'

export type ApplicationEvents = {
  'quiz:answer-valid': boolean
  'quiz:answer-submit': Record<string, unknown>
  'quiz:submit': Record<string, unknown>
}

export default defineNuxtPlugin(() => {
  const emitter = mitt<ApplicationEvents>()

  return {
    provide: {
      event: emitter.emit, // Will emit an event
      listen: emitter.on, // Will register a listener for an event
    },
  }
})
